import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { ToastrService } from 'ngx-toastr';
import { from, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificacaoService {
  private baseURL: string = 'NotificacaoUsuario';
  constructor(
    private httpClient: HttpClient,
    private toastr: ToastrService
  ) {}

  public lerNotificacao(id: number, { onSuccess, onError }: any) {
    this.httpClient
      .post(this.baseURL + '/' + id + '/EfetuarLeitura', {
        headers: {
          'Access-Control-Allow-Origin': '*',
          type: 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      })
      .subscribe({
        next: response => {
          return onSuccess(response);
        },
        error: onError
      });
  }

  public getNotificacao({ onSuccess, onError }: any) {
    this.httpClient.get(this.baseURL).subscribe({
      next: onSuccess,
      error: onError
    });
  }
}
