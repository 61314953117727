<mat-drawer-container class="c-menu-lateral" [hasBackdrop]="mobile">
  <mat-drawer
    class="c-menu-lateral_drawer"
    [mode]="mobile ? 'over' : 'side'"
    [opened]="!mobile">
    <div class="c-menu-lateral_options">
      <app-collapse-menu-lateral (click)="fechaNotificacao()">
      </app-collapse-menu-lateral>
    </div>
  </mat-drawer>
  <mat-drawer-content class="c-menu-lateral_content">
    <ng-content></ng-content>
  </mat-drawer-content>
</mat-drawer-container>
