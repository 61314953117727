import { Injectable } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuLateralService {
  private _drawer?: MatDrawer;
  private _drawerState = new BehaviorSubject<boolean>(false);

  set drawer(drawer: MatDrawer | undefined) {
    this._drawer = drawer;
    if (drawer) {
      drawer.openedChange.subscribe(isOpen => {
        this._drawerState.next(isOpen);
      });
    }
  }

  get drawer(): MatDrawer | undefined {
    return this._drawer;
  }

  toggleDrawer(): void {
    if (this._drawer) {
      this._drawer.toggle();
      this._drawerState.next(this._drawer.opened);
    }
  }

  get drawerState$() {
    return this._drawerState.asObservable();
  }
}
