import { BreakpointObserver } from '@angular/cdk/layout';
import { BREAKPOINTS } from '@core/constants';
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatDrawer } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { AutenticarService } from '@core/services/autenticar.service';
import { MenuLateralService } from '@core/services/menu-lateral.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-inventario-expansion',
  templateUrl: './inventario-expansion.component.html',
  styleUrls: ['./inventario-expansion.component.scss']
})
export class ListagemInventarioExpansionComponent implements AfterViewInit {
  @ViewChild('listagemInventarioExpansionPanel')
  expansionPanel?: MatExpansionPanel;
  @ViewChild(MatDrawer) drawer!: MatDrawer;

  permitir: boolean = false;

  private subscription$: Subscription = new Subscription();
  mobile: boolean = false;
  constructor(
    private router: Router,
    private autenticarService: AutenticarService,

    public menuLateralService: MenuLateralService,
    private breakpointObserver: BreakpointObserver
  ) {}

  rolarParaInicio() {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }

  abrir() {
    const ativo = this.router.isActive('/gestaoAcesso', {
      fragment: 'ignored',
      matrixParams: 'ignored',
      paths: 'subset',
      queryParams: 'ignored'
    });
    if (ativo) {
      this.expansionPanel?.open();
    }
  }

  fecharMenu() {
    if (this.mobile) {
      this.menuLateralService.toggleDrawer();
    }
  }

  ngAfterViewInit(): void {
    this.abrir();
  }

  ngOnInit(): void {
    this.subscription$.add(
      this.breakpointObserver
        .observe([
          BREAKPOINTS.xs,
          BREAKPOINTS.sm,
          BREAKPOINTS.md,
          BREAKPOINTS.lg,
          BREAKPOINTS.xl
        ])
        .subscribe(result => {
          this.mobile =
            result.breakpoints[BREAKPOINTS.xs] ||
            result.breakpoints[BREAKPOINTS.sm] ||
            result.breakpoints[BREAKPOINTS.md] ||
            result.breakpoints[BREAKPOINTS.lg] ||
            result.breakpoints[BREAKPOINTS.xl] ||
            result.breakpoints[BREAKPOINTS.md];
        })
    );
  }
  buscarPermissao(permissaoNome: string): number {
    // Obter as permissões do serviço
    let listaPermissoes = this.autenticarService.getPermissoes();

    // Verificar se listaPermissoes é uma string e tentar parsear
    if (typeof listaPermissoes === 'string') {
      try {
        listaPermissoes = JSON.parse(listaPermissoes);
      } catch (error) {
        console.error('Erro ao parsear listaPermissoes:', error);
        return 0; // Retornar 0 em caso de erro
      }
    }

    // Verifique se listaPermissoes é um array após o parsing
    if (Array.isArray(listaPermissoes)) {
      // Encontrar a permissão com base no nome da permissão
      const permissao = listaPermissoes.find(
        (item: any) => item.Permissao.Nome === permissaoNome
      );

      // Se a permissão for encontrada e o nível de permissão for diferente de 0, definir `permitir` como `true`
      if (permissao && permissao.NivelPermissao !== 0) {
        this.permitir = true;
      }

      // Retornar o nível de permissão ou 0 se não encontrada
      return permissao ? permissao.NivelPermissao : 0;
    } else {
      console.error('listaPermissoes não é um array:', listaPermissoes);
      return 0;
    }
  }
}
