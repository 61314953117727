export const environment = {
  production: true,
  baseURL: 'https://bmac-macae-api.azurewebsites.net/api/v1/',
  rotasSemAutenticacao: [
    'https://bmac-macae-api.azurewebsites.net/api/v1/Autenticacao/Autenticar'
  ],
  dominiosComAutenticacao: ['https://bmac-macae-api.azurewebsites.net'],
  agendamentoCarga:
    JSON.parse(localStorage.getItem('PERFIL') || '{}') === 'USUPETRO'
      ? `https://agendamento.bmac.macae.globalship.com.br?token= ${JSON.parse(
          localStorage.getItem('TOKEN') || '{}'
        )}`
      : 'https://agendamento.bmac.macae.globalship.com.br'
};
