<div class="form-group">
  <label for="{{ name }}" class="label-form" *ngIf="label !== ''">{{
    label
  }}</label>
  <mat-form-field class="input-form">
    <input
      name="{{ name }}"
      matInput
      [formControl]="formControl"
      [type]="type"
      autocomplete="current-password"
      class="form-input"
      [placeholder]="placeholder"
      [required]="isRequired" />
    <button
      *ngIf="originalType === 'password'"
      type="button"
      mat-icon-button
      matSuffix
      (click)="toggleHide()"
      [attr.aria-label]="hide ? 'Hide password' : 'Show password'"
      [attr.aria-pressed]="hide">
      <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
    </button>
  </mat-form-field>
  <app-input-validacao
    *ngIf="showErrorsWhen"
    [control]="control"></app-input-validacao>
</div>
