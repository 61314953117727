import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AutenticarService } from '@core/services/autenticar.service';
import { NotificacaoService } from '@core/services/notificacao.service';
import { TituloPaginaService } from '@core/services/titulo-pagina.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrl: './main.component.scss'
})
export class MainComponent {
  constructor(
    public tituloPagina: TituloPaginaService,
    private router: Router,
    private authService: AutenticarService,

    public notificacaoService: NotificacaoService
  ) {}

  isHomeRoute: boolean = false;

  mostrar = false;

  dataSource!: any[];

  cor: string = '';

  private intervalId: any;

  onMostrarChange(mostrar: any) {
    this.mostrar = mostrar;
  }

  notificacaoLida(flag: boolean) {
    this.getNotificacao();
    this.mostrar = false;
  }

  getNotificacao() {
    this.notificacaoService.getNotificacao({
      onSuccess: (res: any) => {
        this.dataSource = res.Data.ListarNotificacoesUsuario;
        this.verificarCor(this.dataSource);
      },
      onError: (error: any) => {}
    });
  }

  verificarCor(data: any) {
    if (data.length === 0) {
      this.cor = '';
      return '';
    }

    if (data.length > 0) {
      const maiorCriticidade: number = data.reduce(
        (max: any, notificacao: any) =>
          notificacao.Criticidade > max ? notificacao.Criticidade : max,
        this.dataSource[0].Criticidade
      );

      if (maiorCriticidade === 1) {
        this.cor = 'verde';
      } else if (maiorCriticidade === 2) {
        this.cor = 'laranja';
      } else if (maiorCriticidade === 3) {
        this.cor = 'vermelho';
      }

      return this.cor;
    }

    return '';
  }

  fechaNotificacao() {
    this.mostrar = false;
  }
  ngOnInit() {
    // comentando notificacao
    // this.getNotificacao();
    // this.intervalId = setInterval(() => {
    //   this.getNotificacao();
    // }, 300000); // 300000ms = 5 minutos

    if (!this.authService.isAuthenticated()) {
      // Se não estiver logado, redireciona para a página de login
      this.router.navigateByUrl('/login');
    }
    this.router.events.subscribe(val => {
      // Verifica se a rota atual é a rota inicial "/"
      this.isHomeRoute = this.router.url === '/';
      if (this.router.url === '/') {
        this.tituloPagina.defineTitulo('');
      }
    });
  }
}
