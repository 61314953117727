import { BreakpointObserver } from '@angular/cdk/layout';
import { BREAKPOINTS } from '@core/constants';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { TokenPayload } from '@core/models/services/autenticar/tokenPayload';
import { AutenticarService } from '@core/services/autenticar.service';
import { MenuLateralService } from '@core/services/menu-lateral.service';
import { BehaviorSubject, merge, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-cabecalho',
  templateUrl: './cabecalho.component.html',
  styleUrls: ['./cabecalho.component.scss']
})
export class CabecalhoComponent implements OnInit, OnDestroy {
  private subscription$: Subscription = new Subscription();
  menuAberto$ = new BehaviorSubject(true);
  usuario?: TokenPayload;

  mobile: boolean = false;
  @Output() mostrarEnvio = new EventEmitter<boolean>();
  mostrar: boolean = false;

  @Input() cor: string = '';

  constructor(
    public menuLateralService: MenuLateralService,
    private authService: AutenticarService,

    private breakpointObserver: BreakpointObserver
  ) {}

  ngOnInit(): void {
    this.usuario = this.authService.tokenPayload();

    this.subscription$.add(
      this.breakpointObserver
        .observe([
          BREAKPOINTS.xs,
          BREAKPOINTS.sm,
          BREAKPOINTS.md,
          BREAKPOINTS.lg,
          BREAKPOINTS.xl
        ])
        .subscribe(result => {
          this.mobile =
            result.breakpoints[BREAKPOINTS.xs] ||
            result.breakpoints[BREAKPOINTS.sm] ||
            result.breakpoints[BREAKPOINTS.md];

          // Fecha o menu se for mobile
          if (this.mobile && this.menuLateralService.drawer) {
            this.menuLateralService.drawer.close();
          }

          // Configura o observable menuAberto$
          else if (this.menuLateralService.drawer) {
            this.setupMenuAberto();
          }
        })
    );
  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
  }

  private setupMenuAberto(): void {
    // Verifica se 'drawer' está definido antes de acessar 'openedStart' e 'closedStart'
    if (this.menuLateralService.drawer) {
      this.subscription$ = merge(
        this.menuLateralService.drawer.openedStart.pipe(map(() => true)),
        this.menuLateralService.drawer.closedStart.pipe(map(() => false))
      ).subscribe(isOpen => {
        this.menuAberto$.next(isOpen);
      });
    }
  }

  logout(): void {
    this.authService.sair();
  }

  mostrarNotificacao() {
    this.mostrar = !this.mostrar;
    this.mostrarEnvio.emit(this.mostrar);
  }

  toggleMenu(): void {
    this.menuLateralService.toggleDrawer();
  }
}
