<mat-expansion-panel
  #turnoExpansionPanel
  class="c-collapse-menu-lateral_container">
  <mat-expansion-panel-header
    *ngIf="permitir"
    matRipple
    class="c-collapse-menu-lateral_cabecalho">
    <mat-panel-title class="c-collapse-menu-lateral_titulo"
      >Agendamento</mat-panel-title
    >
  </mat-expansion-panel-header>
  <div class="c-collapse-menu-lateral_conteudo">
    <a
      matRipple
      class="c-collapse-menu-lateral_link"
      *ngIf="buscarPermissao('SolicitarAgendamento') != 0"
      routerLinkActive="is-ativo"
      [href]="environment.agendamentoCarga"
      target="_blank"
      >Solicitar agendamento</a
    >
    <a
      matRipple
      class="c-collapse-menu-lateral_link"
      [routerLink]="['/agendamento/listagem/viagem']"
      *ngIf="buscarPermissao('Agendamento') != 0"
      routerLinkActive="is-ativo"
      (isActiveChange)="rolarParaInicio()"
      (click)="fecharMenu()"
      >Agendamento de carga</a
    >
    <a
      matRipple
      class="c-collapse-menu-lateral_link"
      [routerLink]="['/agendamento/listagem/frota']"
      *ngIf="buscarPermissao('Agendamento') != 0"
      routerLinkActive="is-ativo"
      (isActiveChange)="rolarParaInicio()"
      (click)="fecharMenu()"
      >Agendamento de frota</a
    >
    <a
      matRipple
      class="c-collapse-menu-lateral_link"
      [routerLink]="['/agendamento/listagem/consumo']"
      *ngIf="buscarPermissao('Agendamento') != 0"
      routerLinkActive="is-ativo"
      (isActiveChange)="rolarParaInicio()"
      (click)="fecharMenu()"
      >Agendamento de carga de consumo</a
    >
  </div>
</mat-expansion-panel>
