<mat-toolbar
  class="c-cabecalho"
  [ngClass]="{ 'is-menu-aberto': menuAberto$ | async }">
  <div class="c-cabecalho_div">
    <div class="c-cabecalho_header">
      <a [routerLink]="['/']">
        <img
          src="/assets/logo.svg"
          class="c-cabecalho_logo"
          alt="Logo Global Ship" />
      </a>
    </div>
    <button mat-icon-button (click)="toggleMenu()">
      <mat-icon>menu</mat-icon>
    </button>
  </div>

  <div class="c-cabecalho_opcoes">
    <img
      src="/assets/img/icons/notificacao.svg"
      alt=""
      [ngClass]="cor"
      (click)="mostrarNotificacao()"
      *ngIf="usuario?.role === 'USULOGIS'" />
    <button mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu>
      <button mat-menu-item (click)="logout()">
        <mat-icon>logout</mat-icon>
        <span>Sair</span>
      </button>
    </mat-menu>
  </div>
</mat-toolbar>
